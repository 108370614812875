import { graphql } from "gatsby"
import { CaseStudy } from "../CaseStudy/CaseStudy"

export const query = graphql`
  query ($fileAbsolutePath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      frontmatter {
        title
        client
        logo {
          childImageSharp {
            gatsbyImageData
          }
        }
        year
        services
        color
        coverImage {
          childImageSharp {
            gatsbyImageData(height: 470)
          }
        }
        summary
        keyInsights
        caseStudyOutcomes {
          title
          description
        }
      }
      internal {
        content
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`

export default CaseStudy
