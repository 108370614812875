import { PageProps } from "gatsby"
import {
  GatsbyImage,
  getImage,
  getSrc,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import styled from "@emotion/styled"
import ReactMarkdown from "react-markdown"
import React from "react"
import { Layout } from "../components/Layout/Layout"
import { BasePageData } from "../components/Layout/layout.hocs"
import {
  getFooterProps,
  getHeaderProps,
} from "../components/Layout/layout.utils"
import { Typography } from "../components/Typography/Typography"
import { breakpoint } from "../theme/theme"
import { getGradient, Paper } from "../components/Paper/Paper"
import { CaseStudyOutcome } from "../components/CaseStudyOutcome/CaseStudyOutcome"

interface CaseStudyData extends BasePageData {
  markdownRemark: {
    frontmatter: {
      title: string
      caption: string
      client: string
      year: string
      services: string
      color: "green" | "purple" | "blue" | "darkblue"
      coverImage: ImageDataLike
      summary: string
      keyInsights: string
      caseStudyOutcomes: Array<{
        title: string
        description: string
      }>
    }
    internal: {
      content: string
    }
  }
}

type CaseStudyProps = PageProps<CaseStudyData>

const getCoverImage = (coverImage: ImageDataLike) => {
  const image = process.env.STORYBOOK
    ? (coverImage as IGatsbyImageData)
    : getImage(coverImage)
  if (!image) {
    throw new Error("Could not find cover image for casestudy")
  }

  return image
}
export const CaseStudy: React.FC<CaseStudyProps> = (props) => {
  const {
    title,
    client,
    services,
    color,
    coverImage,
    summary,
    keyInsights,
    caseStudyOutcomes,
  } = props.data.markdownRemark.frontmatter
  const siteData = props.data.site.siteMetadata
  const headerProps = props.data.header
  const footerProps = props.data.footer
  const processedCoverImage = getCoverImage(coverImage)
  const content = props.data.markdownRemark.internal.content

  const socialPreviewImage = getSrc(processedCoverImage)

  return (
    <Layout
      title={`${title} | ${siteData.title}`}
      description={summary}
      imageURL={`${siteData.siteUrl}${socialPreviewImage}`}
      header={getHeaderProps(headerProps, "light")}
      footer={getFooterProps(footerProps)}
    >
      <div style={{ minHeight: "100vh" }}>
        <HeroSection>
          <PrimaryHeading variant="h1">{title}</PrimaryHeading>
          <SubHeroSection>
            <SubHeroContainer>
              <CaptionHeading variant="h6">client</CaptionHeading>
              <CaptionContent variant="p">{client}</CaptionContent>
            </SubHeroContainer>

            <SubHeroContainer>
              <CaptionHeading variant="h6">services</CaptionHeading>
              <CaptionContent variant="p">{services}</CaptionContent>
            </SubHeroContainer>
          </SubHeroSection>

          <StyledPaper color={color}></StyledPaper>
          <ImageContainer>
            <GatsbyImage image={processedCoverImage} alt={title} />
          </ImageContainer>
          <SummarySection>
            <div>
              <Typography variant="h3">Summary</Typography>
            </div>
            <div>
              <ReactMarkdown
                components={{
                  p: ({ node: _node, ...props }) => (
                    <Text variant="p" {...props} />
                  ),
                }}
              >
                {summary}
              </ReactMarkdown>
            </div>
          </SummarySection>
          <SummarySection>
            <div>
              <Typography variant="h3">Key Insights</Typography>
            </div>
            <div>
              <ReactMarkdown
                components={{
                  p: ({ node: _node, ...props }) => (
                    <Text variant="p" {...props} />
                  ),
                }}
              >
                {keyInsights}
              </ReactMarkdown>
            </div>
          </SummarySection>
        </HeroSection>
        <MainContainer>
          <Paper color="darkblue">
            <OutcomesSection>
              <OutcomesTitle variant="h3">Project Outcomes</OutcomesTitle>
              <OutcomesContainer>
                {caseStudyOutcomes.map(({ title, description }) => (
                  <CaseStudyOutcome
                    key={title}
                    title={title}
                    markdown={description}
                  />
                ))}
              </OutcomesContainer>
            </OutcomesSection>
          </Paper>
          <MarkdownContainer>
            <ReactMarkdown>{content}</ReactMarkdown>
          </MarkdownContainer>
        </MainContainer>
      </div>
    </Layout>
  )
}

const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  width: "auto",
  padding: "0 2em",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const HeroSection = styled(MaxWidthContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "4em",
  padding: "4em 2em",
})

const PrimaryHeading = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  color: theme.palette.darkblue.main,
}))

const SubHeroSection = styled("div")({
  display: "flex",
  gap: "1em",
  flexDirection: "column",
  [breakpoint("md")]: {
    flexDirection: "row",
    gap: "2em",
  },
})

const SubHeroContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5em",
  [breakpoint("sm")]: {
    gap: "2em",
  },
})
const CaptionHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "12px",
  fontWeight: 800,
  lineHeight: "15px",
  textTransform: "uppercase",
}))

const CaptionContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkblue.main,
  fontWeight: 700,
  fontSize: "16px",
  [breakpoint("sm")]: {
    fontSize: "24px",
  },
}))

const MainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "4em",
  paddingBottom: "6em",
})
const StyledPaper = styled(Paper)(({ color, theme }) => ({
  padding: "1em",
  borderRadius: "12px",
  position: "relative",
  height: "150px",
  clipPath: "polygon(0 0, 100% 0, 100% 70%, 0% 100%)",
  background: getGradient(theme, color),
  [breakpoint("sm")]: {
    height: "250px",
  },
  [breakpoint("md")]: {
    height: "440px",
  },
}))

const ImageContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "-200px",
  padding: "0 40px",
  [breakpoint("sm")]: {
    marginTop: "-250px",
  },
  [breakpoint("md")]: {
    marginTop: "-400px",
  },
})

const SummarySection = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  color: theme.palette.darkblue.main,
  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
}))

const Text = styled(Typography)({
  lineHeight: "36px",
})

const OutcomesSection = styled(MaxWidthContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  padding: "2em",
  [breakpoint("lg")]: {
    padding: "2em 4em",
  },
})

const OutcomesTitle = styled(Typography)({
  color: "#fff",
  [breakpoint("sm")]: {
    textAlign: "center",
  },
})

const OutcomesContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2em",
  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
})

const MarkdownContainer = styled(MaxWidthContainer)(({ theme }) => ({
  color: theme.palette.darkblue.main,
  "& > p": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& > h1": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "32px",
    fontWeight: 800,
    lineHeight: "40px",
  },
  "& > h2": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& > h3": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "20",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& > li": {
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& > img": {
    height: "auto",
    width: "100%",
  },
}))
