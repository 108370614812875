import styled from "@emotion/styled"
import ReactMarkdown from "react-markdown"
import React from "react"
import { Typography } from "../Typography/Typography"

interface CaseStudyOutcomeProps {
  title: string
  markdown: string
}

export const CaseStudyOutcome: React.FC<CaseStudyOutcomeProps> = ({
  title,
  markdown,
}) => {
  return (
    <Container>
      <HeaderContainer>
        <Typography variant="h5">{title}</Typography>
      </HeaderContainer>
      <BodyContainer>
        <ReactMarkdown
          components={{
            h3: ({ node: _node, ...props }) => (
              <Typography variant="h6" {...props} />
            ),
            ul: ({ node: _node, ...props }) => <StyledList {...props} />,
            li: ({ node: _node, children, ...props }) => (
              <li {...props}>
                <StyledItem variant="p">{children}</StyledItem>
              </li>
            ),
            p: ({ node: _node, ...props }) => (
              <Typography variant="p" {...props} />
            ),
          }}
          children={markdown}
        />
      </BodyContainer>
    </Container>
  )
}

const Container = styled("div")(({ theme }) => ({
  boxShadow: theme.boxShadow.light,
  borderRadius: "8px",
}))

const HeaderContainer = styled("div")({
  color: "#FFFFFF",
  padding: "24px",
  background: "#190462",
  borderRadius: "8px 8px 0 0",
})

const BodyContainer = styled("div")(({ theme }) => ({
  background: "#FFFFFF",
  padding: "24px",
  color: theme.palette.darkblue.main,
  borderRadius: "0 0 8px 8px",
}))

const StyledList = styled("ul")({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  paddingLeft: "20px",
})

const StyledItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkblue.main,
  lineHeight: "24px",
}))
